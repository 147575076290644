<template>
  <cs-view single-view>
    <template #content>
      <div class="organizations mt-3 mx-1">
        <div class="pb-2">
          <h3>Organizations</h3>
        </div>
        <v-card class="mx-0">
          <v-card-title class="d-flex">
            <v-spacer></v-spacer>
            <create-organization @organization-created="onOrganizationCreated" />
            <div class="ms-1">
              <cs-button
                id="refresh-organizations-btn"
                primary
                label="Refresh Organizations List"
                @click="forceRefreshOrganizations"
              ></cs-button>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              variant="underlined"
              color="primary"
              single-line
              hide-details
            ></v-text-field>
          </v-card-subtitle>
          <v-data-table
            id="organizations-table"
            v-model="selectedOrganization"
            :row-props="organizationTableRow"
            :headers="organizationsView.headers"
            :items="organizationsView.items"
            :search="search"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.userDirectory="{ item }">
              <span v-if="item.userDirectory">
                <div
                  v-for="(udItem, index) in item.userDirectory
                    .split(',')
                    .map((udItem) => `https://${udItem.trim()}`)"
                  :key="index"
                >
                  <a target="_blank" rel="noopener noreferrer" :href="udItem" class="text-primary">
                    {{ udItem }}
                  </a>
                </div>
              </span>

              <div v-else class="textWarning">Auth0 User Directory not configured</div>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.actions="{ item }">
              <v-icon
                :id="`organization-add-connection-action-${item.id}`"
                size="small"
                class="mr-2"
                @click="openOrganizationConnections(item.id, item.name)"
                >mdi-link</v-icon
              >
            </template>
            <template #no-data>
              <span v-if="loading" id="organizations-loading-text">Loading file...</span>
              <span v-else id="organizations-no-data-text">No data available</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <organization-connection-editor-dialog
        :organization-id="currentOrganizationConnectionsId"
        :organization-name="currentOrganizationConnectionsName"
        :show-dialog="showOrganizationConnections"
        @save="saveOrganizationConnections"
        @close="closeOrganizationConnections"
      ></organization-connection-editor-dialog>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import CreateOrganization from '@/components/CreateOrganization';
import OrganizationConnectionEditorDialog from '@/components/OrganizationConnectionEditorDialog';

export default {
  name: 'OrganizationsPage',

  components: {
    'create-organization': CreateOrganization,
    'organization-connection-editor-dialog': OrganizationConnectionEditorDialog
  },

  extends: CSBase,

  data() {
    return {
      search: '',
      selectedOrganization: [],
      currentOrganizationConnectionsId: '',
      currentOrganizationConnectionsName: '',
      showOrganizationConnections: false
    };
  },

  computed: {
    ...mapGetters({
      organizationsView: 'organizations/organizationsView'
    }),

    ...mapState({
      organizations: (state) => state.organizations.organizations
    })
  },

  async mounted() {
    try {
      this.setLoading(true);
      await this.$store.dispatch('organizations/fetchOrganizations');
      this.clearLoading();
    } catch (e) {
      componentErrorHandler(this, e, undefined, false);
    }
  },

  methods: {
    organizationTableRow() {
      return {
        class: 'organizations-table-row'
      };
    },
    async onOrganizationCreated(organizationId, organizationName) {
      this.openOrganizationConnections(organizationId, organizationName);
      await this.$store.dispatch('organizations/fetchOrganizations');
    },
    async forceRefreshOrganizations() {
      try {
        this.setLoading(true);
        await this.$store.dispatch('organizations/fetchOrganizations', true);
        this.clearLoading();
      } catch (e) {
        componentErrorHandler(this, e, undefined, false);
      }
    },
    openOrganizationConnections(organizationId, organizationName) {
      if (organizationId && organizationName) {
        this.currentOrganizationConnectionsId = organizationId;
        this.currentOrganizationConnectionsName = organizationName;
        this.showOrganizationConnections = true;
      }
    },
    saveOrganizationConnections(
      organizationId,
      organizationName,
      addedResponses,
      removedResponses,
      errorResponses
    ) {
      this.closeOrganizationConnections();

      if (
        errorResponses.length === 0 &&
        (addedResponses.length > 0 || removedResponses.length > 0)
      ) {
        this.showSuccessAlert(
          `Successfully add ${addedResponses.length} connection and remove ${removedResponses.length} connection for ${organizationName} (ID: ${organizationId}).`
        );
        return;
      }

      if (errorResponses.length > 0 && (addedResponses.length > 0 || removedResponses.length > 0)) {
        this.showWarningAlert(
          `Successfully added ${addedResponses.length} connection(s) and removed ${removedResponses.length} connection(s) for ${organizationName} (ID: ${organizationId}). However, ${errorResponses.length} operation(s) encountered errors.`
        );
        return;
      }

      if (
        errorResponses.length > 0 &&
        addedResponses.length === 0 &&
        removedResponses.length === 0
      ) {
        this.showErrorAlert(
          `Failed to add or remove connection(s) for ${organizationName} (ID: ${organizationId}). ${errorResponses.length} operation(s) encountered errors.`
        );
        return;
      }

      this.showWarningAlert(
        `No connection(s) added or removed for ${organizationName} (ID: ${organizationId}).`
      );
    },
    closeOrganizationConnections() {
      this.showOrganizationConnections = false;
      this.currentOrganizationConnectionsId = '';
      this.currentOrganizationConnectionsName = '';
    }
  }
};
</script>
<style scoped>
.textWarning {
  font-style: italic;
  color: grey;
}
</style>
