import { soul } from '@/dependency-injection';
import * as MutationTypes from '@/store/mutationTypes';

const actions = {
  async fetchConnections({ commit }, { page, itemsPerPage, name, forceRefresh = false }) {
    const connectionsData = await soul.getConnections(itemsPerPage, page, name, forceRefresh);
    const { connections, metadata } = connectionsData;

    const itemsLength = metadata?.itemsLength || 0;

    commit(MutationTypes.SET_ALL_CONNECTIONS, { connections, itemsLength });
  }
};

export default actions;
