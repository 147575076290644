<template>
  <div>
    <auth0-organization v-model="selectedRouteName" />
    <v-list
      v-if="isCompliSpaceStaff"
      id="sidebar-organizations-group"
      v-model="selectedRouteName"
      class="left-navigation-organizations"
      nav
      color="primary"
    >
      <div class="text-h6 mt-2 mb-2 text-center">Organizations</div>
      <v-list-item
        :active="isOnOrganizationTab"
        :value="RouteName.ORGANIZATIONS"
        @click="onAllOrganizationsClick"
      >
        <v-list-item-title>All Organizations</v-list-item-title>
      </v-list-item>
      <v-list-item
        :active="isOnConnectionsTab"
        :value="RouteName.CONNECTIONS"
        @click="onConnectionsClick"
      >
        <v-list-item-title>All Connections</v-list-item-title>
      </v-list-item>
      <v-list-item
        :active="isOnSiteConfigTab"
        :value="RouteName.SITE_CONFIG"
        @click="onSiteConfigClick"
      >
        <v-list-item-title>Site Configuration</v-list-item-title>
      </v-list-item>
    </v-list>
    <auth0-connections v-model="connectionName" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Auth0Organization from '@/components/Auth0Organization';
import RouteName from '@/router/routeName';
import Auth0Connections from '@/components/Auth0Connections';

export default {
  name: 'LeftNavigation',
  components: {
    'auth0-organization': Auth0Organization,
    'auth0-connections': Auth0Connections
  },

  data() {
    return {
      selectedRouteName: '',
      connectionName: '',
      RouteName
    };
  },

  computed: {
    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff'
    }),

    selectedConnection() {
      return this.$store.state.organization.selectedConnection;
    },

    isOnOrganizationTab() {
      return this.selectedRouteName === RouteName.ORGANIZATIONS;
    },

    isOnConnectionsTab() {
      return this.selectedRouteName === RouteName.CONNECTIONS;
    },

    isOnSiteConfigTab() {
      return this.selectedRouteName === RouteName.SITE_CONFIG;
    }
  },

  watch: {
    async selectedRouteName(newRouteName, oldRouteName) {
      if (newRouteName && newRouteName !== oldRouteName && newRouteName !== this.$route.name) {
        this.connectionName = '';
        await this.$router.push({ name: newRouteName });
      }
    },

    $route(newRoute, oldRoute) {
      if (newRoute?.name !== oldRoute?.name) {
        this.selectedRouteName = newRoute?.name;
        if (newRoute.name !== RouteName.USERS && newRoute.name !== RouteName.UPLOAD_USERS) {
          this.connectionName = '';
        }
      }
    },

    async connectionName(newConnectionName, oldConnectionName) {
      const isConnectionRoute =
        this.$route.name && [RouteName.USERS, RouteName.UPLOAD_USERS].includes(this.$route.name);
      const routeName = isConnectionRoute ? this.$route.name : RouteName.CONNECTION;
      let query = {};
      if (routeName === RouteName.USERS || routeName === RouteName.CONNECTION) {
        query = { page: '1', itemsPerPage: '10' };
      }

      if (
        newConnectionName &&
        newConnectionName !== oldConnectionName &&
        newConnectionName !== this.$route.params.connectionName
      ) {
        this.selectedRouteName = '';
        await this.$router.push({
          name: routeName,
          params: {
            connectionName: newConnectionName
          },
          query
        });
      }
    }
  },

  created() {
    this.$router.isReady().then(() => {
      this.selectedRouteName = this.$route.name;
      if (this.$route.params.connectionName) {
        this.connectionName = this.$route.params.connectionName;
      }
    });
  },

  methods: {
    async onAllOrganizationsClick() {
      this.selectedRouteName = RouteName.ORGANIZATIONS;
      this.connectionName = '';
    },

    async onConnectionsClick() {
      this.selectedRouteName = RouteName.CONNECTIONS;
      this.connectionName = '';
    },

    onSiteConfigClick() {
      this.selectedRouteName = RouteName.SITE_CONFIG;
      this.connectionName = '';
    }
  }
};
</script>
<style scoped>
.v-list--nav {
  padding: 0;
  margin: 0;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
</style>
