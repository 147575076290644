import Assert from '@/helpers/assert';

const deleteOrganizationConnectionById =
  (soulApiRequest) => async (organizationId, connectionId) => {
    Assert.isPresent(organizationId, 'organizationId');
    Assert.isPresent(connectionId, 'connectionId');

    const response = await soulApiRequest({
      method: 'delete',
      url: `/organization/${organizationId}/connection/${connectionId}`
    });

    return response.data;
  };

export default deleteOrganizationConnectionById;
