<template>
  <cs-form-dialog
    :id="id"
    :model-value="showDialog"
    :heading="heading"
    :primary-action="{
      label: 'Create',
      loading: isCreatingOrganization
    }"
    @primary-click="validateAndCreateOrganization"
    @update:model-value="onCloseDialog"
  >
    <template #cs-form-dialog-content>
      <v-form ref="createOrgForm">
        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="organizationName"
              label="Name"
              color="primary"
              variant="underlined"
              :rules="[
                validationRules.required('Organization Name'),
                validationRules.minMaxLength('Organization Name', 3, 50),
                validationRules.restrictOrgName('Organization Name')
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model.trim="organizationDisplayName"
              label="Display Name"
              color="primary"
              variant="underlined"
              :rules="[
                validationRules.required('Organization Display Name'),
                validationRules.minMaxLength('Organization Display Name', 3, 50),
                validationRules.restrictOrgDisplayName('Organization Display Name')
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </cs-form-dialog>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { soul } from '@/dependency-injection';

export default {
  name: 'CreateOrganizationDialog',

  components: {},
  extends: CSBase,

  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    validationRules: {
      type: Object,
      default() {
        return {
          required: (label) => (v) => !!v || `${label} is required`,
          minMaxLength: (label, min, max) => (v) =>
            (v && v.length <= max && v.length >= min) ||
            `${label} should be within ${min} and ${max} characters`,
          restrictOrgName: (label) => (v) => {
            const isValidOrganizationName = /^[a-z|0-9]+(?:-+[a-z|0-9]+)*$/.test(v);
            return (
              isValidOrganizationName ||
              `${label} must start and end with a lowercase letter or number, may include hyphens, and no uppercase letters`
            );
          },
          restrictOrgDisplayName: (label) => (v) => {
            const isValidOrganizationDisplayName = /^[a-zA-Z 0-9]+$/.test(v);
            return (
              isValidOrganizationDisplayName ||
              `${label} can contain uppercase and lowercase letters, numbers and spaces`
            );
          }
        };
      }
    }
  },

  emits: ['update:modelValue', 'organization-created'],

  data() {
    return {
      showDialog: this.modelValue,
      organizationName: '',
      organizationDisplayName: '',
      isCreatingOrganization: false
    };
  },

  watch: {
    modelValue(val) {
      this.showDialog = val;
    }
  },

  methods: {
    onCloseDialog() {
      this.restoreDefault();
      this.$emit('update:modelValue', false);
    },

    restoreDefault() {
      this.$refs.createOrgForm.reset();
      this.$refs.createOrgForm.resetValidation();
    },

    async isValid() {
      const validation = await this.$refs.createOrgForm.validate();
      return validation.valid;
    },

    async validateAndCreateOrganization() {
      const valid = await this.isValid();
      if (!valid) return;
      try {
        this.isCreatingOrganization = true;
        const { id: organizationId, name: organizationName } = await soul.createOrganizationByName(
          this.organizationName,
          this.organizationDisplayName
        );
        this.isCreatingOrganization = false;
        this.showSuccessAlert(`Organization ${this.organizationName} created successfully`);
        this.onCloseDialog();
        this.$emit('organization-created', organizationId, organizationName);
      } catch (err) {
        this.isCreatingOrganization = false;

        if (err.name === 'Conflict') {
          this.showErrorAlert(`Organization ${this.organizationName} already exists`, true);
          return;
        }

        if (err.name === 'BadGateway') {
          this.showWarningAlert(
            `${err.message}. Please manually add "complispace-staff" connection.`
          );
          this.onCloseDialog();
          this.$emit('organization-created');
          return;
        }

        this.showErrorAlert(err.message, true);
      }
    }
  }
};
</script>
