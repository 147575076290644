<template>
  <cs-form-dialog
    id="organization-connection-editor-dialog"
    :heading="heading"
    :model-value="showDialog && !showConfirmDialog"
    :primary-action="{ label: 'Save', loading }"
    :secondary-action1="{ label: 'Cancel', loading }"
    @update:model-value="onCancel"
    @primary-click="onSave"
    @secondary1-click="onCancel(false)"
  >
    <template #cs-form-dialog-content>
      <div class="container">
        <v-row>
          <v-col>
            <b>Organization Id</b>
            <div>{{ organizationId }}</div>
          </v-col>
          <v-col>
            <b>Organization Name</b>
            <div>{{ organizationName }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Organization Connections</b>
            <span>&nbsp;</span>
            <v-chip color="primary" outlined>
              {{ currentSelectedConnections.length }} of 10
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="connection-list-container">
            <connection-list
              show-select
              no-items-per-page
              :refresh-connection="refreshConnection"
              :initial-selected-connections="currentSelectedConnections"
              :query-options="queryOptions"
              @update:refresh-connection="updateRefreshConnection"
              @update:selected-connections="updateSelectedConnections"
              @update:query-options="onQueryOptionsChange"
            ></connection-list>
          </v-col>
        </v-row>
      </div>
    </template>
  </cs-form-dialog>
  <cs-form-dialog
    id="confirm-organization-connection-editor-dialog"
    :heading="heading"
    :model-value="showConfirmDialog"
    :primary-action="{ label: 'Confirm', loading }"
    :secondary-action1="{ label: 'Cancel', loading }"
    @update:model-value="onCancelConfirm"
    @primary-click="onConfirm"
    @secondary1-click="onCancelConfirm"
  >
    <template #cs-form-dialog-content>
      <div class="container">
        <v-row>
          <v-col>
            <b>Organization Id</b>
            <div>{{ organizationId }}</div>
          </v-col>
          <v-col>
            <b>Organization Name</b>
            <div>{{ organizationName }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Added Connections</b>
            <v-badge color="primary" :content="addedConnections.length" inline></v-badge>
            <v-list lines="one">
              <v-list-item
                v-for="connection in addedConnections"
                :key="connection.id"
                :title="connection.name"
                :subtitle="`Id: ${connection.id}, Strategy: ${connection.strategy}`"
              ></v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <b>Removed Connections</b>
            <v-badge color="error" :content="removedConnections.length" inline></v-badge>
            <v-list lines="one">
              <v-list-item
                v-for="connection in removedConnections"
                :key="connection.id"
                :title="connection.name"
                :subtitle="`Id: ${connection.id}, Strategy: ${connection.strategy}`"
              ></v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Preserved Connections</b>
            <v-badge color="warning" :content="preservedConnections.length" inline></v-badge>
            <v-list lines="one">
              <v-list-item
                v-for="connection in preservedConnections"
                :key="connection.id"
                :title="connection.name"
                :subtitle="`Id: ${connection.id}, Strategy: ${connection.strategy}`"
              ></v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b
              >Updating organization connections may require adjustments in "Site Configuration" to
              accommodate the new changes. Do you want to proceed with saving these changes?</b
            >
          </v-col>
        </v-row>
      </div>
    </template>
  </cs-form-dialog>
  <progress-dialog
    id="organization-connection-progress-dialog"
    cancel-btn-label="Cancel"
    heading="Updating Organization Connections"
    progress-bar-id="organization-connection-progress-bar"
    :model-value="showProgressDialog"
    :progress-percent="progressPercent"
    @progress-cancel="onProgressDialogCancel"
  ></progress-dialog>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import ConnectionList from '@/components/ConnectionList';

import ProgressDialog from '@/components/ProgressDialog';

import { soul } from '@/dependency-injection';
import delayForEnv from '@/helpers/delayForEnv';

export default {
  name: 'OrganizationConnectionEditorDialog',

  components: {
    'connection-list': ConnectionList,
    'progress-dialog': ProgressDialog
  },

  extends: CSBase,

  props: {
    organizationId: {
      type: String,
      default: ''
    },
    organizationName: {
      type: String,
      default: ''
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: 'Edit Organization Connections'
    }
  },

  emits: ['save', 'close'],

  data() {
    return {
      refreshConnection: false,
      originalSelectedConnections: [],
      currentSelectedConnections: [],
      queryOptions: {
        itemsPerPage: 10,
        page: 1
      },
      loading: false,
      closeConfirmDialogAndRefresh: false,
      showConfirmDialog: false,
      showProgressDialog: false,
      progressPercent: 0,
      cancelProgress: false
    };
  },

  computed: {
    addedConnections() {
      return this.currentSelectedConnections.filter(
        (connection) =>
          !this.originalSelectedConnections.some(
            (originalConnection) => originalConnection.id === connection.id
          )
      );
    },

    removedConnections() {
      return this.originalSelectedConnections.filter(
        (originalConnection) =>
          !this.currentSelectedConnections.some(
            (connection) => connection.id === originalConnection.id
          )
      );
    },

    preservedConnections() {
      return this.originalSelectedConnections.filter((originalConnection) =>
        this.currentSelectedConnections.some(
          (connection) => connection.id === originalConnection.id
        )
      );
    }
  },

  watch: {
    showDialog(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.init();
        }
      });
    },
    closeConfirmDialogAndRefresh(newVal) {
      if (newVal) {
        this.showConfirmDialog = false;
        this.$nextTick(() => {
          this.refreshConnection = true;
          this.closeConfirmDialogAndRefresh = false;
        });
      }
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true;
        this.queryOptions = {
          itemsPerPage: 10,
          page: 1
        };
        this.refreshConnection = true;
        const { connections } = await soul.getConnectionsByOrganizationId(this.organizationId);
        this.originalSelectedConnections = [...connections];
        this.currentSelectedConnections = [...connections];
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.showErrorAlert(`Failed to load organization connections. ${e.message}.`);
        this.$emit('close', false);
      }
    },
    onQueryOptionsChange(options) {
      this.queryOptions = options;
    },
    onSave() {
      if (
        !this.originalSelectedConnections.some(
          (connection) => connection.name === 'complispace-staff'
        ) &&
        !this.currentSelectedConnections.some(
          (connection) => connection.name === 'complispace-staff'
        )
      ) {
        this.showErrorAlert(
          'It looks like this organization is missing a required connection. Please add the "complispace-staff" connection before saving.',
          true
        );
        return;
      }

      if (this.currentSelectedConnections.length > 10) {
        this.showErrorAlert('You can only select up to 10 connections.', true);
        return;
      }

      this.showConfirmDialog = true;
    },
    onCancel(value) {
      this.$emit('close', value);
    },
    async onConfirm() {
      if (this.loading) {
        return;
      }

      const bulkConnectionActions = [
        ...this.addedConnections.map((connection) => ({ ...connection, actionType: 'add' })),
        ...this.removedConnections.map((connection) => ({ ...connection, actionType: 'remove' }))
      ];

      this.progressPercent = 0;
      this.cancelProgress = false;
      this.showProgressDialog = true;

      const addedActions = [];
      const removedActions = [];
      const failedActions = [];

      for (const [index, currentActions] of bulkConnectionActions.entries()) {
        if (this.cancelProgress) {
          break;
        }

        const actionIndex = index + 1;
        const totalActions = bulkConnectionActions.length;

        this.progressPercent = (actionIndex / totalActions) * 100;

        switch (currentActions.actionType) {
          case 'add':
            try {
              await soul.createOrganizationConnectionById(this.organizationId, currentActions.id);
              addedActions.push(`Added connection ${currentActions.id} to ${this.organizationId}`);
            } catch (error) {
              failedActions.push(
                `Error add connection ${currentActions.id} to ${this.organizationId}: ${error.message}`
              );
            }
            break;
          case 'remove':
            try {
              await soul.deleteOrganizationConnectionById(this.organizationId, currentActions.id);
              removedActions.push(
                `Removed connection ${currentActions.id} from ${this.organizationId}`
              );
            } catch (error) {
              failedActions.push(
                `Error remove connection ${currentActions.id} from ${this.organizationId}: ${error.message}`
              );
            }
            break;
          default:
            break;
        }

        await delayForEnv();
      }

      this.loading = false;
      this.showProgressDialog = false;
      this.showConfirmDialog = false;
      this.refreshConnection = false;

      this.$emit(
        'save',
        this.organizationId,
        this.organizationName,
        addedActions,
        removedActions,
        failedActions
      );
    },
    onCancelConfirm() {
      if (this.loading) {
        return;
      }

      this.closeConfirmDialogAndRefresh = true;
    },
    updateRefreshConnection(newVal) {
      this.refreshConnection = newVal;
    },
    updateSelectedConnections(values) {
      if (values.length !== this.currentSelectedConnections.length) {
        this.currentSelectedConnections = [...values];
      }
    },
    onProgressDialogCancel() {
      this.loading = true;
      this.cancelProgress = true;
    }
  }
};
</script>
<style scoped>
.connection-list-container {
  padding: 0;
}
</style>
