<template>
  <div :class="['connection-list', { 'connection-list-no-items-per-page': noItemsPerPage }]">
    <div class="connection-list-txt-search">
      <v-text-field
        id="connections-search-text-field"
        v-model="searchName"
        append-icon="mdi-magnify"
        label="Please search connections by full name"
        variant="underlined"
        color="primary"
        single-line
        hide-details
        @update:model-value="searchNameDebounce"
      ></v-text-field>
    </div>
    <v-data-table-server
      id="connections-table"
      v-model="selectedConnections"
      class="connections-table"
      :row-props="connectionTableRow"
      :headers="connectionsView.headers"
      :items="connectionsView.items"
      :items-length="itemsLength"
      :loading="loadingBar"
      loading-text="Loading connections..."
      return-object
      dense
      :page="options.page"
      :items-per-page="options.itemsPerPage"
      :disable-sort="true"
      :items-per-page-options="[10, 25, 50, 100]"
      :show-select="showSelect"
      @update:page="onUpdatePage"
      @update:items-per-page="onUpdateItemsPerPage"
    >
      <template #[`header.data-table-select`]>
        <div class="text-center">#</div>
      </template>
      <template #[`item.data-table-select`]="{ item }">
        <v-checkbox
          :model-value="selectedConnections"
          :value="item"
          multiple
          hide-details
          color="primary"
          @update:model-value="onSelectedConnectionsChange"
        />
      </template>
    </v-data-table-server>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import debounce from 'lodash/debounce';

export default {
  name: 'ConnectionList',

  extends: CSBase,

  props: {
    queryOptions: {
      type: Object,
      default() {
        return { itemsPerPage: 10, page: 1 };
      }
    },
    refreshConnection: {
      type: Boolean,
      default: false
    },
    forceRefresh: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    initialSelectedConnections: {
      type: Array,
      default: () => []
    },
    noItemsPerPage: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:refreshConnection',
    'update:queryOptions',
    'update:selectedConnections',
    'update:forceRefresh'
  ],

  data() {
    return {
      searchName: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      selectedConnections: []
    };
  },

  mounted() {
    this.selectedConnections = [...this.initialSelectedConnections];
  },

  computed: {
    ...mapGetters({
      connectionsView: 'connections/connectionsView',
      itemsLength: 'connections/itemsLength'
    }),

    loadingBar() {
      return this.isLoading() ? 'primary' : false;
    }
  },

  watch: {
    async refreshConnection(newVal) {
      if (newVal) {
        this.options = { ...this.queryOptions };
        await this.onRefreshConnections();
        this.$emit('update:refreshConnection', false);
      }
    },
    queryOptions: {
      async handler(newValue) {
        await this.fetchConnections(newValue);
        this.options = newValue;
      },
      deep: true,
      once: true
    },
    async forceRefresh(newVal) {
      if (newVal) {
        await this.fetchConnections(this.options, true);
        this.$emit('update:forceRefresh', false);
      }
    },
    initialSelectedConnections(newValue) {
      this.selectedConnections = [...newValue];
    }
  },

  methods: {
    connectionTableRow(item) {
      const isDisabledComplispaceStaffRow =
        this.showSelect &&
        item.item.name === 'complispace-staff' &&
        (this.selectedConnections.some((selected) => selected.name === 'complispace-staff') ||
          this.initialSelectedConnections.some(
            (selected) => selected.name === 'complispace-staff'
          ));

      return {
        class: 'connections-table-row complispace-staff-row',
        style: isDisabledComplispaceStaffRow ? 'pointer-events: none; opacity: 0.6' : ''
      };
    },

    searchNameDebounce: debounce(function _() {
      this.onSearchNameChange();
    }, 1000),

    async onSearchNameChange() {
      if (this.options.page > 1) {
        this.options.page = 1;
      }
      await this.onRefreshConnections();
    },

    updateOptions(page, itemsPerPage) {
      this.options.page = page;
      this.options.itemsPerPage = itemsPerPage;
      this.$emit('update:queryOptions', this.options);
    },

    async fetchConnections(options, forceRefresh = false) {
      try {
        this.setLoading(true);

        const fetchOpt = {
          page: options.page,
          itemsPerPage: options.itemsPerPage
        };

        if (this.searchName) {
          fetchOpt.name = this.searchName;
        }

        if (forceRefresh) {
          fetchOpt.forceRefresh = true;
        }

        await this.$store.dispatch('connections/fetchConnections', fetchOpt);

        if (this.itemsLength === 0) {
          this.updateOptions(1, 10);
        }

        this.clearLoading();
      } catch (e) {
        this.clearLoading();
        componentErrorHandler(this, e, undefined, false);
      }
    },

    async onUpdateItemsPerPage(itemsPerPage) {
      this.updateOptions(1, itemsPerPage);
      await this.onRefreshConnections();
    },

    async onUpdatePage(page) {
      this.updateOptions(page, this.options.itemsPerPage);
      await this.onRefreshConnections();
    },

    async onRefreshConnections() {
      const { itemsPerPage, page } = this.options;
      await this.fetchConnections({
        itemsPerPage,
        page
      });
    },

    onSelectedConnectionsChange(newSelected) {
      this.selectedConnections = newSelected;
      this.$emit('update:selectedConnections', newSelected);
    }
  }
};
</script>
<style scoped>
.connection-list-txt-search {
  opacity: var(--v-medium-emphasis-opacity);
  margin: 0 14px;
}
</style>
<style>
.connection-list-no-items-per-page .v-data-table-footer__items-per-page {
  display: none !important;
}
</style>
