import Assert from '@/helpers/assert';

const createOrganizationConnectionById =
  (soulApiRequest) => async (organizationId, connectionId) => {
    Assert.isPresent(organizationId, 'organizationId');
    Assert.isPresent(connectionId, 'connectionId');

    const response = await soulApiRequest({
      method: 'post',
      url: `/organization/${organizationId}/connection`,
      data: {
        connectionId
      }
    });

    return response.data;
  };

export default createOrganizationConnectionById;
