import pick from 'lodash/pick';

export const keys = ['id', 'name', 'displayName', 'userDirectory', 'actions'];
export const headerTexts = ['Id', 'Name', 'Display Name', 'User Directory Url', 'Actions'];

const getters = {
  organizationsView(state) {
    const { organizations } = state;
    const itemsWithNoUrl = [];

    if (!organizations.length) {
      return {
        headers: [],
        items: [],
        itemsWithNoUrl
      };
    }
    const items = organizations.map((organization) => {
      const base = pick(organization, [...keys]);
      base.userDirectory = '';
      let hasNoUdAppUrl = true;

      const udAppUrl = organization.userDirectoryUrl ? organization.userDirectoryUrl : '';

      base.userDirectory = udAppUrl;
      if (udAppUrl !== '') {
        hasNoUdAppUrl = false;
      }

      const viewItem = {
        ...base
      };
      if (hasNoUdAppUrl) {
        itemsWithNoUrl.push(viewItem);
      }
      return viewItem;
    });
    const headers = keys.map((value, index) => {
      const title = headerTexts[index];
      const key = value;
      const sortable = ['userDirectory', 'actions'].indexOf(value) < 0;
      const col = {
        title,
        key,
        sortable
      };
      return col;
    });
    return {
      headers,
      items,
      itemsWithNoUrl
    };
  }
};

export default getters;
