<template>
  <cs-view single-view>
    <template #content>
      <div class="connections mt-3 mx-1">
        <div class="pb-2">
          <h3>Connections</h3>
        </div>
        <v-card class="mx-0 connection-list-container">
          <v-card-title class="d-flex">
            <v-spacer></v-spacer>
            <create-connection @connection-created="refreshConnection = true" />
            <div class="ms-1">
              <cs-button
                id="refresh-connection-btn"
                primary
                label="Refresh Connections List"
                @click="forceRefreshConnection = true"
              ></cs-button>
            </div>
          </v-card-title>
          <connection-list
            :refresh-connection="refreshConnection"
            :query-options="queryOptions"
            :force-refresh="forceRefreshConnection"
            @update:refresh-connection="updateRefreshConnection"
            @update:query-options="updateQueryOptions"
            @update:force-refresh="updateForceRefreshConnection"
          ></connection-list>
        </v-card>
      </div>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import ConnectionList from '@/components/ConnectionList';
import CreateConnection from '@/components/CreateConnection';
import RouteName from '@/router/routeName';

export default {
  name: 'ConnectionsPage',

  components: { ConnectionList, CreateConnection },

  extends: CSBase,

  data() {
    return {
      refreshConnection: false,
      queryOptions: { itemsPerPage: 10, page: 1 },
      forceRefreshConnection: false
    };
  },

  computed: {},

  async mounted() {
    await this.initQueryOptions();
  },

  methods: {
    async initQueryOptions() {
      const { query } = this.$route;
      const itemsPerPage = parseInt(query.itemsPerPage || '10', 10);
      const page = parseInt(query.page || '1', 10);
      await this.updateQueryOptions({
        page,
        itemsPerPage: this.validateItemsPerPage(itemsPerPage) ? itemsPerPage : 10
      });
    },
    validateItemsPerPage(itemsPerPage) {
      return [10, 25, 50, 100].includes(itemsPerPage);
    },
    updateRefreshConnection(newVal) {
      this.refreshConnection = newVal;
    },
    async updateQueryOptions(newVal) {
      this.queryOptions = newVal;
      await this.$router.push({
        name: RouteName.CONNECTIONS,
        query: {
          page: this.queryOptions.page,
          itemsPerPage: this.queryOptions.itemsPerPage
        }
      });
    },
    updateForceRefreshConnection(newVal) {
      this.forceRefreshConnection = newVal;
    }
  }
};
</script>
<style scoped>
.textWarning {
  font-style: italic;
  color: grey;
}
</style>
