import range from 'lodash/range';
import Organizations from '../../models/Organizations';
import getOrganizations from './getOrganizations';

const getAllOrganizations =
  (soulApiRequest) =>
  async (forceRefresh = false) => {
    const itemsPerPage = 50;

    let allOrganizations = Organizations.create();

    const { organizations: firstPage, metadata } = await getOrganizations(soulApiRequest)(
      itemsPerPage,
      1,
      forceRefresh
    );

    allOrganizations = allOrganizations.concatenate(firstPage);

    const { itemsLength } = metadata;
    const totalPages = Math.ceil(itemsLength / itemsPerPage);

    if (totalPages > 1) {
      const pages = range(2, totalPages + 1);

      const promises = pages.map((page) =>
        getOrganizations(soulApiRequest)(itemsPerPage, page, forceRefresh)
      );
      const responses = await Promise.all(promises);

      responses.forEach((response) => {
        allOrganizations = allOrganizations.concatenate(response.organizations);
      });
    }

    return allOrganizations;
  };

export default getAllOrganizations;
